<template>
  <div id="topiclocal">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
#topiclocal {
  // height: 100%;
  background-color: #fff;
  padding: 31px 78px 0 75px;
}
</style>